.infi_footer_section {
    padding: 140px 0px 30px 0px;
    background: linear-gradient(279deg, #151552 59.78%, #3C4373 118.94%);
    position: relative;
    overflow: hidden;
}

.infi_footer {
    position: absolute;
    top: 0;
    height: 100%;
    left: -20px;
}

.ft_tele_circle {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: 1px solid white;
    margin-bottom: 1rem;
    position: absolute;
    right: 0;
    bottom: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.5s;
}

.ft_tele_circle:hover {
    background: white;
    color: #151552;
    transition: all 0.5s;
}
.ft_tele_circle:hover svg{
    color: #151552;
}

.infi_newsletter_inp svg {
    color: white;
    font-size: 32px;
}


.infi_footer_down {
    position: absolute;
    right: 5.5%;
    top: 70px;
}

.footer_news_title h2 {
    font-size: 90px;
    color: white;
    letter-spacing: 3px;
}

.footer_news_title p {
    color: white;
    margin-top: 10px;
    font-size: 28px;
    letter-spacing: 2px;
}

.news_icn label {
    color: white;
}

/* .news_inp input::before{
    border-color: white !important;
    color: white !important;
}

.news_inp input::after{
    border-color: white !important;
    color: white !important;
} */

.news_inp input {
    border-bottom: 2px solid white;
    color: white;
}

.news_inp ::after,
.news_inp :focus,
.news_inp :hover {
    color: white;
    border-bottom: 2px solid white !important;

}

.news_inp label {
    color: white !important;
}

.infi_newsletter_inp input {
    border: none;
    border-bottom: 1px solid rgb(255 255 255 / 47%);
    background: none;
    font-size: 24px;
    padding: 10px 10px 15px 0px;
    width: 100%;
    color: white;
    font-family: system-ui;
    font-weight: 300;
}

.infi_newsletter_inp input::placeholder {
    color: rgb(255 255 255 / 47%);
}


.infi_newsletter_inp {
    display: flex;
    align-items: center;
    position: relative;
    margin-top: 20px;
}

.infi_footer_address ul li span {
    margin-right: 10px;
}

.infi_footer_address ul li span img{
    margin-right: 5px;
    margin-top: 8px;
}

.infi_footer_address ul li p {
    color: #ffffff;
    line-height: 40px;
    font-size: 25px;
}

.infi_footer_address ul li {
    display: flex;
    margin-bottom: 25px;
}


.infi_footer_contact_text {
    display: flex;
    margin-right: 25px;
}

.infi_footer_social_icn {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 15px 0px;
    border-top: 1px solid #ffffffbd;
    position: relative;
    transition: .6s cubic-bezier(0, .89, .41, 1);
    overflow: hidden;
    z-index: 999;
    cursor: pointer;
    color: white;
}

.infi_footer_social_icn:hover {
    border-top: 1px solid #ffffff;
    padding: 15px 20px;
}

.social_text h2 {
    letter-spacing: 1px;
    margin-bottom: 5px;
}

.infi_footer_social_icn:after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: translateY(-101%);
    content: "";
    background-color: white;
    transition: .2s;
    z-index: -1;
    z-index: -99;
}


.infi_footer_social_icn:hover::after {
    transform: translateY(0%);
}

.infi_footer_social_icn:hover {
    color: #151552;
}

.infi_footer_social_icn:hover .social_icon svg {
    color: white;
}

.infi_footer_social_icn:hover .social_icon {
    background: #151552;
}

.social_icon {
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 10px;
    background: white;
    border-radius: 50%;
}

.social_icon svg {
    color: black;
    font-size: 26px;
}

.infi_footer_address ul li:nth-last-child(1) {
    margin-bottom: 0;
}

.infi_footer_copyright {
    border-top: 1px solid rgb(255 255 255 / 47%);
    padding-top: 20px;
    margin-top: 80px;
    color: white;
}

.infi_footer_copyright p {
    letter-spacing: 1px;
}


@media (min-width: 992px) and (max-width: 1024px) {
    .infi_footer_address {
        margin-bottom: 5rem;
        margin-top: 5rem;
    }

    .infi_newsletter_inp {
        margin-top: 50px;
    }

    .infi_footer_section {
        padding: 110px 0px 30px 0px;
    }
    
    .address_1 {
        display: flex;
    }

}

@media (min-width: 768px) and (max-width: 992px) {
    .infi_footer_address {
        margin-bottom: 5rem;
        margin-top: 5rem;
    }

    .address_1 {
        display: flex;
    }

    .infi_newsletter_inp {
        margin-top: 50px;
    }

    .infi_footer_section {
        padding: 110px 0px 30px 0px;
    }

    .footer_news_title p {
        font-size: 20px;
    }
    .text_ani_mt {
        margin-top: 10px;
    }

}

@media (min-width: 320px) and (max-width: 767px) {
    .footer_news_title h2 {
        font-size: 40px;
        color: white;
        letter-spacing: 3px;
    }

    .address_1 {
        display: flex;
    }

    .infi_footer_section {
        padding: 70px 0px 30px 0px;
    }
    .infi_newsletter_inp {
        margin-top: 0px;
        margin-bottom: 40px;
    }
    
    .infi_newsletter_inp svg {
        font-size: 25px;
        right: 0;
    }
    .ft_tele_circle {
        width: 45px;
        height: 45px;
    }

    
    .infi_newsletter_inp input {
        font-size: 18px;
        padding: 10px 10px 15px 0px;
        width: 100%;
        font-weight: 300;
    }
    
    .infi_footer_address ul li p {
        line-height: 26px;
        font-size: 18px;
        margin-top: 5px;
    }

    .footer_news_title p {
        font-size: 18px;
        line-height: 26px;
    }

    .infi_footer_down {
        top: 25px;
        width: 42px;
    }

    .infi_footer_address ul {
        margin-bottom: 1.5rem;
    }
    .infi_footer_address ul li {
        display: block;
    }
    .infi_footer_contact_text {
        align-items: center;
        margin-bottom: 10px;

    }
    .infi_footer_copyright {
        margin-top: 30px;
        text-align: center;
    }
    .infi_footer_copyright p {
        line-height: 24px;
    }
    img.infi_footer {
        display: none;
    }
    .slogan__content {
        overflow: hidden;
    }
    .text_ani_mt {
        margin-top: 10px;
    }

}