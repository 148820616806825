.header_top {
    background: #15155200;
    position: absolute;
    width: 100%;
    transition: all 0.5s;
    z-index: 999;
    padding-top: 10px;
}

.sticky-top {
    position: fixed;
    background: #151552;
    width: 100%;
    transition: all 0.5s;
    z-index: 999;
}

.main_header {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0px 10px 0px;
    width: 92%;
    margin: auto;
}

.menu_item {
    align-items: center;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    width: 75%;
}

.menu_item ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
}

.menu_item ul li {
    padding: 0px 15px;
}

.menu_item ul li a {
    text-decoration: none;
    color: white;
    font-size: 20px;
    letter-spacing: 1px;
}

button.header_btn,
button.header_btn:focus {
    border-color: white;
    color: white;
    padding: 9px 37px;
    border-radius: 0;
    font-size: 18px;
    font-family: 'TomatoGrotesk', sans-serif !important;
    font-weight: 600 !important;
    letter-spacing: 4px;
}

button.header_btn:hover {
    background-color: white;
    color: #24275f;
    transition: 0.5s;
    border-color: #24275f;
    font-family: 'TomatoGrotesk', sans-serif !important;
    font-weight: 600 !important;
}

header.header_top.sticky-top .header_logo {
    width: 150px;
    transition: all 0.5s;
}

.header_logo {
    width: 190px;
    transition: all 0.5s;
}

.header_logo img {
    width: 100%;
}


.mobile_drawer_main a {
    text-decoration: none;
    font-size: 16px;
    font-weight: 600;
    color: #24275f;
    padding: 10px 0px;
}

.mobile_drawer_main {
    width: 250px;
}


@media (min-width: 992px) and (max-width: 1024px) {
    header.header_top.fixed_header {
        position: fixed;
        top: 0;
        background: #23265e;
        z-index: 999;
        padding-top: 0;
    }

    header.header_top.fixed_header .header_logo {
        width: 110px;
        transition: 0.5s;
    }
}

@media (min-width: 768px) and (max-width: 992px) {

    .header_logo {
        width: 150px;
        transition: all 0.5s;
    }

    .menu_toggle_btn svg {
        font-size: 38px;
        margin-top: 10px;
    }

    header.header_top.fixed_header {
        position: fixed;
        top: 0;
        background: #23265e;
        z-index: 999;
        padding-top: 0;
    }

    header.header_top.fixed_header .header_logo {
        width: 110px;
        transition: 0.5s;
    }

}

@media (min-width: 320px) and (max-width: 767px) {
    .header_logo {
        width: 140px;
        transition: all 0.5s;
    }

    header.header_top.fixed_header .header_logo {
        width: 110px;
        transition: 0.5s;
    }

    .menu_toggle_btn svg {
        font-size: 38px;
        color: white;
    }

    header.header_top.fixed_header {
        position: fixed;
        top: 0;
        background: #23265e;
        z-index: 999;
        padding-top: 0;
    }
}