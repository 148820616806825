.contact_section {
    height: 100vh;
    background: linear-gradient(279deg, #151552 59.78%, #3C4373 118.94%);
    padding: 50px 0px;
    display: flex;
    align-items: center;
    overflow: auto;
}

.contact_title h2 {
    font-size: 100px;
    font-style: normal;
    line-height: normal;
    color: white;
    font-weight: 600 !important;
}

.contact_inp.cuntry_phone_number .react-international-phone-country-selector button {
    background: none;
    border: none;
    border-bottom: 1px solid #5c5c86;
    border-radius: 0;
    height: 45px;
}
.contact_inp.cuntry_phone_number input.react-international-phone-input {
    background: none;
    color: white;
    border: none;
    border-bottom: 1px solid #5c5c86;
    border-radius: 0;
    width: 100%;
    height: 45px;
}

.contact_title p {
    font-size: 30px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    color: white;
    margin-top: 45px;
    
}

.back_btn {
    max-width: 100%;
    margin-bottom: 30px;
}


.contact_inp .MuiInputLabel-animated {
    color: white !important;
    font-size: 30px;
    padding: 0px 0px 10px 0px;
    font-weight: 300 !important;
    font-family: 'TomatoGrotesk', sans-serif;
}

.contact_inp .MuiInput-underline {
    color: rgb(182, 182, 182) !important;
    font-weight: 300 !important;
    padding: 0px 0px 5px 0px;
    font-family: 'TomatoGrotesk', sans-serif;
    font-size: 18px;
    margin-top: 5px
}

.contact_inp .MuiInput-underline::before {
    opacity: 0.3;
    border-color: #fff;
}

.contact_inp .MuiInput-underline::after {
    border-color: #fff;
}

.contact_inp textarea {
    text-align: left;
    font-family: 'TomatoGrotesk', sans-serif;
    font-size: 18px;
    line-height: 25px;
    margin-top: 0.5rem;
}
.contact_title {
    padding-right: 1rem;
}

.contact_inp label {
    color: white;
    font-size: 30px;
}

.contact_logo {
    margin-top: 1.5rem;
    position: relative;
    top: -1rem;

}

.back_btn.circle {
    width: 100px;
    height: 100px;
    border: 2.5px solid white;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.5s;
}

.back_btn.circle:hover {
    background-color: #ffffff;
    transition: all 0.5s;
}

.back_btn.circle:hover svg {
    color: #151552;
}

.back_btn.circle svg {
    font-size: 64px;
    color: white;
    position: relative;
    top: 2px;
    left: 2px;
}

button.contact_sub_btn {
    border-color: white;
    color: white;
    border-radius: 0px;
    padding: 8px 40px;
    transition: all 0.5s;
    letter-spacing: 4px;
    font-family: 'TomatoGrotesk', sans-serif !important;
    font-weight: 600 !important;
}

button.contact_sub_btn {
    padding: 12px 45px;
}

button.contact_sub_btn:hover {
    background-color: white;
    color: #151552;
    border-color: white;
    transition: all 0.5s;
    font-weight: 600 !important;
    font-family: 'TomatoGrotesk', sans-serif !important;
}

.contact_logo.back_btn.mob {
    display: none;
}


@media (min-width:1200px) and (max-width:1440px) {
    .contact_title {
        padding-right: 2rem;
    }

    .contact_title h2 {
        font-size: 85px;
    }
}

@media (min-width:768px) and (max-width:1024px) {
    .contact_title p {
        font-size: 20px;
        line-height: 28px;
        margin-top: 15px;
    }

    .contact_title h2 {
        font-size: 75px;
    }

    .contact_inp label {
        font-size: 24px;
    }

    .top_item_main {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .contact_logo.back_btn.mob {
        display: block;
    }

    .contact_logo.back_btn.desk {
        display: none;
    }

    .contact_title {
        margin-bottom: 2rem;
    }

    .back_btn.circle {
        width: 85px;
        height: 85px;
    }

    .back_btn.circle svg {
        font-size: 56px;
    }
}

@media (min-width:320px) and (max-width:767px) {
    .contact_title p {
        font-size: 16px;
        line-height: 24px;
        margin-top: 15px;
    }

    .contact_inp .MuiInput-underline {
        font-size: 15px;
    }

    .contact_title h2 {
        font-size: 38px;
    }

    .contact_inp label {
        font-size: 20px;
    }

    .contact_inp {
        margin-bottom: 2rem;
    }

    .back_btn.circle {
        width: 75px;
        height: 75px;
    }

    .back_btn.circle svg {
        font-size: 48px;
    }

    .top_item_main {
        display: block;
        justify-content: space-between;
        align-items: center;
    }

    .contact_logo.back_btn.mob {
        display: block;
    }

    .contact_logo.back_btn.desk {
        display: none;
    }

    .contact_title {
        margin-bottom: 2rem;
    }

}