.infi_similar_title {
    font-size: 80px;
    width: 79%;
    line-height: 82px;
    position: relative;
    font-weight: 500;
}


.infi_similar_desc {
    font-size: 30px;
    color: black;
    width: 45%;
    margin-top: 20px;
    font-weight: 600;
}

/** banner css **/
.banner_section {
    background-image: url('../images/home/banner-img.png');
    height: 100vh;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    position: relative;
    background-size: cover;
}


.banner_title_content {
    width: max-content;
    margin-left: 10%;
    margin-top: 0px;
}

.banner_title_content h2 {
    font-size: 140px;
    color: white;
    font-weight: 300;
    line-height: 135px;
    margin: 0;
    font-weight: 400 !important;
}

.banner_since {
    position: absolute;
    bottom: 14%;
    left: 0px;
    transform: rotate(-90deg);
}

.banner_since h3 {
    margin: 0;
    color: #24275f;
    -webkit-text-fill-color: #ffffff;
    -webkit-text-stroke-width: 0.5px;
    -webkit-text-stroke-color: #272a61;
    font-size: 30px;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1.5px;
    
}

.social_icn {
    position: absolute;
    right: 4%;
}

.social_icn ul li {
    padding: 11px 0px;
}

.social_icn ul li div svg {
    color: white;
    font-size: 18px;
}

.banner_social_ic:hover svg {
    color: #1e205a;
}

.banner_social_ic:hover {
    background: white;
    transition: all 0.5s;
}

.banner_social_ic {
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid white;
    border-radius: 50%;
    transition: all 0.5s;
    cursor: pointer;
}

.social_icn ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.banner_title_content .title1 {
    position: relative;
    left: 13.5%;
}

.banner_title_content .title2 {
    position: relative;
    left: 25%;
}

.banner_title_content .title4 {
    position: relative;
    left: 25%;
}

.banner_scroll_down .img_down {
    color: white;
    font-size: 44px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 55px;

}

.banner_scroll_down {
    position: absolute;
    bottom: 30px;
    right: 18%;
    cursor: pointer;
}

.scroll_img {
    -webkit-animation: spin 2s linear infinite;
    animation: spin 10s linear infinite;
}


.banner_text_description {
    width: 20%;
    right: 16%;
    position: absolute;
    bottom: 35%;
}

.banner_text_description h3 {
    color: white;
    font-size: 33px;
    font-weight: 400 !important;
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

/** banner css **/

/* logo Slide css*/


/* LogoSlider.css */

.marquee-container {
    overflow: hidden;
    white-space: nowrap;
    position: relative;
    width: 100%;
}

.marquee-text {
    display: flex;
    white-space: nowrap;
    will-change: transform;
}


.logo-slider {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    display: flex;
}

/* .marquee {
    display: inline-block;
    animation: scroll-left linear infinite;
}

.marquee {
    display: flex;
    animation: scroll-left linear infinite;
} */

.logo-item {
    padding: 0 30px;
    /* Adjust padding as needed */
}

.address_1 {
    display: flex;
}

.logo-item img {
    width: max-content;
    cursor: pointer;
    height: max-content;
    filter: grayscale(100%);
}

.logo-item:hover img {
    filter: inherit;
    transition: all 0.2s;
}

@keyframes scroll-left {
    from {
        transform: translateX(100%);
    }

    to {
        transform: translateX(-100%);
    }
}



/* .logo_item img {
    display: block;
    max-width: 100%;
    margin: auto;
    filter: grayscale(100%);
    cursor: pointer;
} */



.logo_item:hover img {
    filter: inherit;
    transition: all 0.2s;
}

.logo_slide_title h2 {
    color: #CECECE;
}

.logo_slide_title {
    padding: 0px 20px;
    background: rgb(249 249 249);
    z-index: 999;
    position: absolute;
}

/* .logo_slide_title {
    float: left;
    padding: 0px 20px;
} */

.logo_slide_section {
    padding: 30px 0px;
    overflow: hidden;
}

.logo_slide_main .slick-track {
    display: flex;
    align-items: center;
}

/* .logo_item {
    display: flex !important;
    align-items: center;
    justify-content: flex-start;
    padding: 0px 10px;
} */

.logo_item p {
    font-weight: bolder !important;
    font-size: 30px;
    line-height: 36px;
    text-transform: capitalize;
}

.logo_item {
    text-align: center;
}



.logo_item span {
    font-size: 120px;
    -webkit-text-fill-color: #ffffff;
    -webkit-text-stroke-width: 0px;
    -webkit-text-stroke-color: #ffffff;
    text-shadow: 2px 0 black, 0 -1px black, -2px 0 black, 0 1px black;
    font-weight: 900 !important;
    margin-right: 1rem;
    text-align: center;
    width: 160px;
    height: 150px;
    /* box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px; */

}


/* logo Slide css*/

/* Text animation css*/
.slogan__content {
    position: relative;
    width: 100%;
    text-align: center;
    margin: auto;
}

.text_animation_section {
    background: ffffff;
    padding: 160px 0px 80px 0px;
}

.text--l1 {
    font-size: 40px;
    line-height: 89%;
    line-height: normal;
}

.text--l1,
.text--l2,
.text--l3 {
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    text-transform: capitalize;
    line-height: 50px;
}

.slogan__karaoke-content>span {
    position: relative;
    display: inline-block;
    white-space: nowrap;
}

.slogan__karaoke-mask {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
}

.slogan__karaoke-mask>span {
    position: relative;
    color: #0000;
    display: inline-block;
    white-space: nowrap;
    transform: scaleX(1);
    transform-origin: right;
    transition-duration: 1s;
    transition-property: transform;
}

.slogan__karaoke-mask>span.aos-animate {
    transform: scaleX(0);
}

.slogan__karaoke-mask [data-aos] {
    transition-duration: 1s !important;
}

.slogan--animated span.aos-animate {
    transform: scaleX(0);
}

.slogan__karaoke-mask>span:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 105%;
    background-color: #F9F9F9;
    opacity: .8;
}

.slogan__karaoke-content>span {
    position: relative;
    display: inline-block;
    white-space: nowrap;
}

.slogan .test {
    position: absolute;
    visibility: hidden;
    height: auto;
    width: auto;
    white-space: nowrap;
}

*,
*:before,
*:after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: unset !important;
}

.slogan__karaoke-mask>span {
    position: relative;
    color: #0000;
    display: inline-block;
    white-space: nowrap;
    transform: scaleX(1);
    transform-origin: right;
    transition-duration: 1s;
    transition-property: transform;
}


.slogan {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    padding-top: 5.625rem;
    padding-bottom: 5.625rem;
    background-color: #e9e7e1;
    color: #1e1e1e;
}

.text_ani_mt {
    margin-top: 40px;
}

/* Text animation css*/

/* Project Info css */

.project_section {
    padding: 110px 0px;
}

.service_box {
    padding: 25px;
    /* box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px; */
    overflow: hidden;
    position: relative;
    background-color: white;
}

.service_box:hover {
    background: none;
}

.service_box:hover .service_hov_content {
    visibility: hidden;
}

.service_box:hover .service_hov_h2 {
    transform: translate(0px, -150px);
    transition: all 0.6s;
    z-index: 999;
    color: white;
}

.service_hov_h2 {
    transition: all 0.6s;
}

.service_box:hover .service_overlay {
    height: 100%;
    transition: all 0.5s;
    bottom: 0;
    z-index: -88;
}

.service_overlay ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.service_box .service_overlay p {
    width: 100%;
    display: flex;
    align-items: center;
    color: white;
    font-size: 20px;
    margin-bottom: 5px;
}

.service_box .service_overlay h2 {
    margin-top: 0;
    visibility: hidden;
}

.service_overlay svg {
    margin-right: 10px;
    color: white;
}

.service_overlay h2 {
    color: white;
}

.service_overlay {
    position: absolute;
    width: 100%;
    left: 0;
    padding: 15px 25px;
    height: 0;
    bottom: -50px;
    background: #14144F;
    transition: all 0.5s;
}

.service_box h2 {
    font-size: 40px;
    font-style: normal;
    line-height: normal;
    text-transform: capitalize;
    margin: 20px 0px;
    font-weight: 900 !important;
}

.service_box p {
    color: #7B7B7B;
    line-height: 32px;
    font-size: 24px;
}


.service_title h2:before {
    content: "";
    position: absolute;
    top: -20px;
    left: 0;
    width: 60%;
    height: 2px;
    background: #060636;
}

/* .service_title p {
    font-size: 30px;
    color: black;
    width: 45%;
    margin-top: 15px;
} */

.service_section {
    padding: 110px 0px 60px 0px;
}


/* How we Work */

.how_work_section {
    padding: 80px 0px;
}


.how_we_work_title h2:before {
    content: "";
    position: absolute;
    top: -20px;
    left: 0;
    width: 60%;
    height: 2px;
    background: #060636;
}

.work_content p {
    color: #000000;
    line-height: 28px;
    font-weight: 600;
    font-size: 18px;
    opacity: 0.5;
}

.work_content {
    width: 43%;
    margin-left: 25px;
    position: relative;
    top: -5px;
}

.work_count_circle {
    width: 47px;
    height: 47px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #060636;
    background-color: white;
    border: 1px solid #060636;
}

.work_content h2 {
    color: #060636;
    margin-bottom: 8px;
    font-size: 40px;
    font-weight: 400;
}

.work_box {
    display: flex;
    height: 180px;
    position: relative;
}

/* .work_box::before {
    content: "";
    position: absolute;
    height: 0 !important;
    width: 2px;
    background: #DFDFDF !important;
    left: 24px;
    z-index: -88;
    transition-duration: 2s !important;
    transform: scaleX(1);
} */

/* .work_box.aos-animate::before {
    content: "";
    position: absolute;
    height: 100% !important;
    width: 2px;
    background: #060636 !important;
    left: 24px;
    z-index: -88;
    transform-origin: bottom;
    transition-duration: 2s !important;
} */

/* .work_box.aos-init::after {
    content: "";
    position: absolute;
    height: 100% !important;
    width: 2px;
    background: #DFDFDF !important;
    left: 24px;
    z-index: -8888;
    transition-duration: 1s !important;
    transform: scaleX(1);
} */

.work_box::before[data-aos] {
    transition-duration: 1s !important;
}

.how_work_main .work_box:nth-last-child(1)::before {
    display: none;
}

.how_work_main .work_box:nth-last-child(1)::after {
    display: none;
}

.work_box.aos-animate .work_count_circle {
    background: #060636;
    color: white;
    transition-duration: 2s !important;
}

.how_work_main {
    margin-top: 11rem;
    margin-bottom: 3rem;
    margin-left: 5rem;
}

.work_box {
    margin-bottom: 30px;
}

.work_sticky-top {
    position: sticky;
    top: 80px;
}



/* Recent Work */

.recent_work_section {
    padding: 110px 0px;
}

.re_work_main {
    display: grid;
    grid-template-columns: auto auto auto auto;
    grid-gap: 10px;

}

.re_work_main>div {
    padding: 20px 0;
}

/* .re-box1 {
    grid-area: 1 / 1 / span 2 / span 2;
}

.re-box2 {
    grid-area: span 2 / span 2;
}

.re-box3 {
    grid-area: 3 / 1 / span 2 / span 2;
}

.re-box4 {
    grid-area: span 3 / span 2;
}

.re-box5 {
    grid-area: span 6 / span 2;
}

.re-box6 {
    grid-area: span 6 / span 1;
} */

/* .recent_work_tab_menu {
    display: flex;
    justify-content: flex-end;
    position: relative;
    top: -4rem;
} */

.recent_work_img_box {
    overflow: hidden;
}

.recent_work_img_box img {
    width: 100%;
    height: 100%;
}

.recent_work_tab_menu {
    margin-bottom: 50px;
}

.recent_work_tab_menu .MuiTabs-flexContainer {
    justify-content: flex-end;
}

.recent_work_tab_menu button {
    font-weight: 400;
    font-size: 18px;
}

.recent_work_sticky {
    position: sticky;
    top: 0px;
    background-color: #f9f9f9;
}

.recent_work_tab_menu button {
    font-size: 32px;
    opacity: 0.5;
    padding: 12px 50px;
    text-transform: capitalize;
    border-bottom: 1px solid #D9D9D9;
    font-family: 'TomatoGrotesk', sans-serif !important;
    font-weight: 700;
}

.recent_work_tab_menu button.Mui-selected {
    color: #060636;
    border-color: #060636;
    opacity: 1;
}

.recent_work_tab_menu .MuiTabs-indicator {
    background-color: #060636;
}

.revemp_right_box ul li p {
    width: 45%;
    font-size: 24px;
    line-height: 32px;
}

/* Revemp **/
.revemp_section {
    padding: 80px 0px 80px 0px;
}

section.revemp_section .infi_similar_desc {
    width: 66%;
    margin-bottom: 25px;
}

ul.rev_ul1 li::marker {
    font-size: 35px;
}

.revemp_right_box {
    background: #151552;
    background: linear-gradient(279deg, #151552 59.78%, #3C4373 118.94%);
    padding: 50px 70px;
    color: white;
}

ul.rev_ul1 li {
    margin-bottom: 30px;
}

.revemp_right_box ul li h2 {
    font-size: 30px;
    margin-bottom: 8px;
    font-weight: 600;
}

ul.rev_ul1 {
    width: 70%;
}

ul.rev_ul2 {
    width: 57%;
}

ul.rev_ul2 {
    list-style: none;
}

ul.rev_ul2 li:nth-child(1)::marker {
    font-size: 35px;
}

.revemp_btn,
.revemp_btn:focus,
.revemp_btn:focus:active {
    border-radius: 0 !important;
    margin-top: 10px !important;
    background: #151552 !important;
    outline: none !important;
    box-shadow: none !important;
    padding: 10px 30px !important;
    font-size: 18px !important;
    font-family: 'TomatoGrotesk', sans-serif !important;
    font-weight: 600 !important;
    letter-spacing: 3px !important;
}

.rev_text_desc {
    display: flex;
    width: 60%;
    justify-content: space-between;
    margin-top: 10px;
}

ul.rev_ul2 h2 {
    padding-bottom: 10px;
}

/** Why We Work **/


.section.why_work_section .infi_similar_title h2 {
    font-weight: 600 !important;
}

.section.why_work_section .infi_similar_title h2 strong {
    font-weight: 800 !important;
    color: #000000;
}

.why_work_section {
    padding: 100px 0px;
}

.why_work_main {
    margin-top: 11rem;
}

.why_work_main ul li {
    display: flex;
    justify-content: space-between;
    padding: 10px 10px 15px 0px;
    border-top: 1px solid #000000;
    position: relative;
}

.why_desc {
    transform: translate(0px, 25px);
    transition: all 0.5s;
}

.why_desc p {
    opacity: 0;
    transition: all 0.5s;
    margin-top: 5px;
    width: 65%;
    font-weight: 300 !important;
    line-height: 20px;
    color: #8b8b8b;
    margin-left: 4.5rem;
}

.why_work_main ul li:hover .why_desc {
    transition: all 0.5s;
    transform: translate(0px, 0px);
}

.why_work_main ul li:hover .why_desc p {
    opacity: 1;
}

.why_work_icon {
    position: absolute;
    right: 0;
    top: 10px;
    transition: all 0.5s;
}

.why_work_main ul li:hover .why_work_icon {
    transform: rotate(45deg);
    transition: all 0.5s;
}

.why_work_text_content {
    display: flex;
    align-items: center;
}

.why_work_icon svg {
    font-size: 42px;
}

.why_work_text_content h3 {
    font-size: 30px;
}

.why_work_text_content img {
    margin-right: 2.5rem;
    width: 35px;
}

/* Technologies **/

.technologies_section {
    padding: 120px 0px 0px 0px;
    height: 1250px;
    position: relative;
    display: flex;
    overflow: hidden;
}

.para_main_text {
    width: max-content;
    height: max-content;
    margin: auto;
}

.para_main_text h2 {
    font-size: 100px;
    text-transform: capitalize;
    font-weight: 500 !important;
    text-align: center;
}

.para_main_text h2 strong {
    font-weight: 800 !important;
}

.para_main_text.para_sticky-top {
    position: sticky;
    bottom: 0px;
}

.para_logo_main {
    position: absolute;
    width: 100%;
    height: 100%;
}

.para_item1 {
    position: absolute;
    left: 10rem;
    top: 14rem;
}

.para_item2 {
    position: absolute;
    left: 19rem;
    top: 19rem;
}

.para_item3 {
    position: absolute;
    left: 2rem;
    top: 20rem;
}

.para_item4 {
    position: absolute;
    left: 20rem;
    top: 47rem;
}

.para_item5 {
    position: absolute;
    left: 27rem;
    top: 28rem;
}

.para_item6 {
    position: absolute;
    left: 52rem;
    top: 60rem;
}

.para_item7 {
    position: absolute;
    top: 37rem;
    left: 45rem;
}

.para_item8 {
    position: absolute;
    right: 5rem;
    margin: auto;
    width: max-content;
    top: 4rem;
}

.para_item9 {
    position: absolute;
    right: -53rem;
    left: 0;
    width: max-content;
    margin: auto;
    top: 23rem;
}

.para_item10 {
    position: absolute;
    right: 0;
    left: 53rem;
    margin: auto;
    top: 22rem;
}

.para_item11 {
    position: absolute;
    right: 16rem;
    top: 11rem;
}

.para_item12 {
    position: absolute;
    right: 12rem;
    top: 31rem;
}

.para_item13 {
    position: absolute;
    top: 40rem;
    right: 21rem;
}

/* start project */



.start_proj_main {
    border-bottom: 3px solid #060636;
    width: max-content;
    position: relative;
    cursor: pointer;
}

.st_over_hid {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
}

.start_proj_main:after {
    content: "";
    position: absolute;
    left: -70px;
    width: 0px;
    height: 3px;
    background: white;
    transition: all 0.5s;
    bottom: -3px;
}

.start_proj_main:hover:after {
    left: 100%;
    transition: all 0.4s;
}

.start_project_section {
    padding: 10px 0px 140px 0px;
}

.start_proj_p {
    font-size: 30px;
    font-weight: 600;
}

.start_proj_main h2 {
    color: #060636;
    font-size: 100px;
    line-height: normal;
    width: max-content;
    padding-bottom: 20px;
    padding-right: 15px;
}

.static_title {
    transition: all 0.5s;
}

.start_proj_main h2 svg {
    position: relative;
    top: 25px;
    right: 0px;
    transform: rotate(0deg);
    transition: all 0.5s;
}


.start_proj_main:hover .static_title svg {
    transform: rotate(5deg);
    top: -63px;
    right: -77px;
}


.start_proj_main:hover .hover_title svg {
    transform: rotate(5deg);
    top: 23px;
    right: 0px;
}

.proj_start_title {
    position: relative;
    overflow: hidden;
    width: max-content;
}

.hover_title {
    position: absolute;
    transform: translate3d(0px, 10px, 10px);
    transition: all 0.5s;
}

.start_proj_main:hover .proj_start_title .hover_title {
    transform: translate3d(0px, -150px, 10px);
    transition: all 0.5s;
}

.start_proj_main:hover .proj_start_title .static_title {
    transform: translate3d(0px, -150px, 10px);
    transition: all 0.5s;
}

.hover_title {
    position: absolute;
}

.start_proj_main h2 {
    color: #060636;
    font-size: 100px;
    line-height: normal;
    width: max-content;
    padding-bottom: 20px;
    padding-right: 15px;
}

section.start_project_section a {
    text-decoration: none;
    color: inherit;
}

.arrow_static {
    font-size: 85px;
    transform: translate3d(0%, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
    transition: all 0.5s;
    color: #060636;
}

.arrow_hover {
    font-size: 85px;
    transform: translate3d(90%, -90%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
    transition: all 0.5s;
}

.arrow_hover {
    font-size: 85px;
    transform: translate3d(-180%, 50%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
    position: absolute;
    bottom: 0px;
}

svg.arrow_hover {
    opacity: 0;
}

.start_proj_main:hover svg.arrow_static {
    transform: translate3d(90%, -70%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
    transition: all 0.5s;
}

.start_proj_main:hover svg.arrow_hover {
    transform: translate3d(-105%, -35%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
    transition: all 0.5s;
    opacity: 1;
}

.arrow_up {
    overflow: hidden;
}

.menu_toggle_btn {
    display: none;
}

.menu_toggle_btn svg {
    font-size: 28px;
    color: white;
}


@media (min-width: 1200px) and (max-width: 1280px) {
    .banner_title_content h2 {
        font-size: 110px;
        line-height: 110px;
    }
}

@media (min-width: 768px) {
    .service_hov_content p {
        min-height: 100px;
    }
}

@media (max-width: 1024px) {
    .menu_item {
        display: none;
    }

    .header_btn_main button {
        display: none;
    }

    .menu_toggle_btn {
        display: block !important;
    }


}

@media (min-width: 992px) and (max-width: 1024px) {

    .service_section .work_sticky-top {
        margin-bottom: 30px;
    }

    .project_section {
        padding: 60px 0px 30px 0px;
    }

    .text_animation_section {
        padding: 80px 0px 20px 0px;
    }

    .masonary1 {
        margin-top: 90px;
    }

    .infi_similar_title {
        width: 100%;
        font-size: 70px;
        line-height: 78px;
    }

    .infi_similar_desc {
        width: 80%;
    }

    .recent_work_section {
        padding: 80px 0px 80px 0px;
    }

    .how_work_main {
        margin-top: 2rem;
        margin-bottom: 0;
    }

    .how_work_section {
        padding: 60px 0px 0px 0px;
    }

    .revemp_right_box {
        margin-top: 30px;
    }

    .revemp_right_box ul li p {
        width: 100%;
        font-size: 22px;
        line-height: 30px;
    }

    .revemp_section {
        padding: 60px 0px 60px 0px;
    }

    .why_work_main {
        margin-top: 2rem;
    }

    .start_project_section {
        padding: 10px 0px 100px 0px;
    }

    .recent_work_tab_menu {
        padding-top: 10px;
        top: 0rem;
        margin-bottom: 4rem;
    }

    .recent_work_tab_menu button {
        padding: 12px 40px;
    }

    .recent_work_tab_menu button {
        font-size: 26px;
    }

    .banner_section {
        height: 700px;
    }

    .banner_text_description {
        width: 27%;
        right: 11%;
        bottom: 40%;
    }

    .banner_text_description h3 {
        color: white;
        font-size: 24px;
        font-weight: 400 !important;
    }

    .banner_since {
        position: absolute;
        bottom: 4%;
        left: 40px;
        transform: rotate(0deg);
    }

    .banner_title_content h2 {
        font-size: 100px;
        line-height: 100px;
    }

    .banner_title_content {
        margin-left: 12%;
    }

    .banner_title_content {
        width: max-content;
        margin-left: 8%;
        margin-top: 0px;
    }

    .header_logo {
        width: 160px;
        transition: all 0.5s;
    }

    .menu_toggle_btn svg {
        font-size: 40px;
        color: white;
    }

    .para_item1 {
        position: absolute;
        left: 3rem;
        top: 0rem;
    }

    .para_item2 {
        position: absolute;
        left: 35rem;
        top: 75rem;
    }

    .para_item3 {
        position: absolute;
        left: 16rem;
        top: 88rem;
    }

    .para_item4 {
        position: absolute;
        left: 1.5rem;
        top: 57rem;
    }

    .para_item5 {
        position: absolute;
        left: 25rem;
        top: 33rem;
    }

    .para_item6 {
        position: absolute;
        left: 15rem;
        top: 19rem;
    }

    .para_item7 {
        position: absolute;
        top: 87rem;
        left: 4rem;
    }

    .para_item8 {
        position: absolute;
        right: 5rem;
        margin: auto;
        width: max-content;
        top: 4rem;
    }

    .para_item9 {
        position: absolute;
        right: 30px;
        left: inherit;
        width: max-content;
        top: 46rem;
    }

    .para_item10 {
        position: absolute;
        right: 0;
        left: 24rem;
        margin: auto;
        top: 25rem;
    }

    .para_item11 {
        position: absolute;
        right: 16rem;
        top: 23rem;
    }

    .para_item12 {
        position: absolute;
        right: 3rem;
        top: 33rem;
    }

    .para_item13 {
        position: absolute;
        top: 50rem;
        right: 26rem;
    }

    .para_main_text h2 {
        font-size: 80px;
    }

}

@media (min-width: 768px) and (max-width: 992px) {

    .text_animation_section {
        padding: 60px 0px 20px 0px;
    }

    .service_section {
        padding: 80px 0px 60px 0px;
    }

    
    .logo_slide_title {
        position: inherit;
    }

    .logo_slide_title {
        float: none;
        padding: 0px 0px;
        margin: auto;
        text-align: center;
        margin-bottom: 1rem;
    }

    .technologies_section {
        height: 1300px;
    }

    .para_main_text h2 {
        font-size: 60px;
    }

    .infi_similar_desc {
        font-size: 26px;
        color: black;
        width: 80%;
        line-height: 35px;
    }

    .infi_similar_title {
        font-size: 70px;
        width: 100%;
        line-height: 72px;
    }


    .banner_section {
        height: 650px;
    }

    .banner_title_content h2 {
        font-size: 80px;
        line-height: 85px;
    }

    .banner_since {
        bottom: 14%;
        left: -30px;
        transform: rotate(-90deg);
    }

    .banner_text_description {
        width: 40%;
        right: 0;
        position: absolute;
        bottom: 30px;
        left: 0;
        margin: auto;
    }

    .banner_text_description h3 {
        color: white;
        font-size: 22px;
        font-weight: 400 !important;
    }

    .banner_scroll_down {
        position: absolute;
        bottom: 25px;
        right: 5%;
    }

    .scroll_img img {
        width: 130px;
    }

    .banner_scroll_down .img_down {
        font-size: 44px;
        left: 4px;
        width: 33px;
    }

    .banner_title_content {
        margin-left: 30%;
        margin-top: 0px;
    }

    .slogan__karaoke-content>span {
        position: relative;
        display: block;
        white-space: initial;
    }

    .text--l1 {
        font-size: 32px;
    }

    .service_box {
        padding: 35px 30px 50px 30px;
    }

    .service_box h2 {
        font-size: 34px;
    }

    section.service_section .service_title.infi_hm_similar_desc.work_sticky-top {
        margin-bottom: 2rem;
    }

    .recent_work_tab_menu {
        top: 0rem;
        margin-bottom: 4rem;
    }

    .recent_work_tab_menu button {
        font-size: 18px;
        opacity: 0.5;
        padding: 12px 25px;
    }

    .how_work_main {
        margin-top: 3rem;
        margin-bottom: 0;
    }

    .work_content {
        width: 100%;
    }

    .how_work_section {
        padding: 80px 0px 0px 0px;
    }

    section.revemp_section .infi_similar_desc {
        width: 100%;
    }

    .revemp_right_box {
        margin-top: 2rem;
    }

    .revemp_right_box ul li p {
        width: 100%;
        font-size: 24px;
        line-height: 32px;
    }

    ul.rev_ul2 {
        width: 100%;
    }

    .recent_work_section {
        padding: 90px 0px;
    }

    .rev_text_desc {
        width: 100%;
    }

    ul.rev_ul1 {
        width: 100%;
    }

    .project_section {
        padding: 60px 0px 30px 0px;
    }

    .start_proj_main h2 {
        font-size: 65px;
    }

    .start_proj_main:hover .proj_start_title .hover_title {
        transform: translate3d(0px, -90px, 10px);
    }

    .start_proj_main:hover svg.arrow_hover {
        transform: translate3d(-105%, -5%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
    }

    .why_work_main {
        margin-top: 2rem;
    }


    .para_item1 {
        position: absolute;
        left: 3rem;
        top: 0rem;
    }

    .para_item2 {
        position: absolute;
        left: 35rem;
        top: 75rem;
    }

    .para_item3 {
        position: absolute;
        left: 16rem;
        top: 88rem;
    }

    .para_item4 {
        position: absolute;
        left: 1.5rem;
        top: 57rem;
    }

    .para_item5 {
        position: absolute;
        left: 25rem;
        top: 33rem;
    }

    .para_item6 {
        position: absolute;
        left: 15rem;
        top: 19rem;
    }

    .para_item7 {
        position: absolute;
        top: 87rem;
        left: 4rem;
    }

    .para_item8 {
        position: absolute;
        right: 5rem;
        margin: auto;
        width: max-content;
        top: 4rem;
    }

    .para_item9 {
        position: absolute;
        right: 30px;
        left: inherit;
        width: max-content;
        top: 46rem;
    }

    .para_item10 {
        position: absolute;
        right: 0;
        left: 24rem;
        margin: auto;
        top: 25rem;
    }

    .para_item11 {
        position: absolute;
        right: 16rem;
        top: 23rem;
    }

    .para_item12 {
        position: absolute;
        right: 3rem;
        top: 33rem;
    }

    .para_item13 {
        position: absolute;
        top: 50rem;
        right: 26rem;
    }


    .technologies_section {
        padding: 0px 0px 0px 0px;
        overflow: hidden;
    }


}


@media (min-width: 320px) and (max-width: 767px) {
    .revemp_right_box ul li p {
        width: 100%;
        font-size: 18px;
        line-height: 26px;
    }
    .banner_social_ic {
        width: 32px;
        height: 32px;
    }

    .social_icn ul li {
        padding: 6px 0px;
    }

    .social_icn {
        position: absolute;
        right: 4%;
        bottom: -240px;
        top: 0;
        margin: auto;
        height: min-content;
    }

    .social_icn ul li div svg {
        color: white;
        font-size: 12px;
    }

    ul.rev_ul1 li::marker {
        font-size: 20px;
    }

    ul.rev_ul2 li:nth-child(1)::marker {
        font-size: 20px;
    }

    .social_text h2 {
        font-size: 22px;
    }

    .banner_since h3 {
        font-size: 26px;
    }

    .logo_slide_section {
        padding: 60px 0px 0px 0px;
        overflow: hidden;
    }

    .logo_slide_title h2 {
        font-size: 30px;
        margin-bottom: 2rem;
    }

    .logo_slide_title {
        float: none;
        padding: 0px 0px;
        margin: auto;
        text-align: center;
        margin-bottom: 1rem;
    }

    .logo_slide_title {
        position: inherit;
    }

    ul.rev_ul1 li {
        margin-bottom: 10px;
    }

    ul.rev_ul1 {
        width: 100%;
        padding-left: 15px;
    }

    ul.rev_ul2 {
        width: 100%;
        padding-left: 15px;
    }

    .rev_text_desc {
        width: 100%;
    }

    .revemp_section {
        padding: 30px 0px 60px 0px;
    }


    .banner_section {
        height: 90vh;
    }

    .banner_title_content {
        width: max-content;
        margin-left: 18%;
        margin-top: -100px;
    }

    .banner_title_content h2 {
        font-size: 48px;
        color: white;
        font-weight: 300;
        line-height: 50px;
        margin: 0;
        font-weight: 400 !important;
    }

    .banner_text_description {
        width: 65%;
        right: 0;
        position: absolute;
        bottom: 220px;
        left: -18px;
        margin: auto;
    }

    .banner_text_description h3 {
        color: white;
        font-size: 18px;
        font-weight: 300 !important;
    }

    .banner_scroll_down {
        position: absolute;
        bottom: 50px;
        right: 0;
        left: 0;
        margin: auto;
        width: fit-content;
    }

    .scroll_img img {
        width: 100px;
    }

    .banner_scroll_down .img_down {
        font-size: 44px;
        width: 30px;
    }

    .banner_since {
        position: absolute;
        bottom: -240px;
        left: -55px;
        transform: rotate(-90deg);
        top: 0;
        margin: auto;
        height: fit-content;
    }

    .banner_since img {
        width: 60%;
    }

    .banner_scroll_down .img_down {
        top: -3px;
    }

    .text--l1 {
        font-size: 22px;
        line-height: 38px;
    }

    .slogan__karaoke-content>span {
        display: block;
        white-space: none;
    }

    .text_animation_section {
        padding: 60px 0px 60px 0px;
    }

    .project_section {
        padding: 0px 0px 50px 0px;
    }

    .logo_item {
        display: block !important;
        justify-content: center;
        padding: 0px 15x;
        text-align: center;
        margin-bottom: 0rem;
    }

    .logo_item p {
        font-size: 20px;
        line-height: 25px;
    }

    .logo_item span {
        font-size: 70px;
        margin-right: 0rem;
    }


    .service_section {
        padding: 60px 0px 60px 0px;
    }

    .work_sticky-top {
        text-align: left;
    }


    .infi_similar_title {
        font-size: 40px;
        width: 100%;
        line-height: 46px;
        
    }

    .infi_similar_desc {
        font-size: 18px;
        width: 100%;
        margin-top: 15px;
        line-height: 26px;
    }

    .service_title h2:before {
        top: -20px;
        left: 0;
        width: 50%;
        margin: auto;
    }

    .infi_similar_desc {
        font-size: 18px;
        width: 100%;
        margin-top: 15px;
        line-height: 26px;
    }

    .service_box {
        padding:30px 20px;
        text-align: left;
    }

    .service_overlay {
        position: absolute;
        width: 100%;
        left: 0;
        padding: 30px 25px;
        height: 0;
        bottom: -70px;
        background: #14144F;
        transition: all 0.5s;
    }

    .service_box h2 {
        font-size: 22px;
        margin: 10px 10px 10px 5px;
    }

    .service_box p {
        line-height: 22px;
        font-size: 15px !important;
    }

    .service_box:hover .service_hov_h2 {
        transform: translate(0px, -135px);
    }

    .how_we_work_title h2:before {
        left: 0;

        margin: auto;
    }

    .recent_work_tab_menu {
        display: flex;
        top: 0rem;
        margin-top: 2rem;
        justify-content: center;
    }

    .recent_work_section {
        padding: 50px 0px 50px 0px;
    }

    .recent_work_tab_menu .MuiTabs-indicator {
        display: none;
    }

    .recent_work_tab_menu button {
        font-size: 28px;
        padding: 12px 20px;
    }

    .recent_work_img_box {
        width: 100% !important;
    }

    .tabs_item .MuiTabs-flexContainer {
        display: grid !important;
    }

    .how_work_main {
        margin-top: 0rem;
        margin-left: 4rem;
    }

    .work_content {
        width: 100%;
    }

    .work_content p {
        line-height: 22px;
        font-size: 15px;
    }

    .how_work_section {
        padding: 60px 0px 0px 0px;
    }

    section.revemp_section .infi_similar_desc {
        width: 100%;
        margin-bottom: 10px;
    }

    .revemp_right_box {
        padding: 20px 40px 30px 40px;
    }

    .revemp_right_box ul li h2 {
        font-size: 20px;
        line-height: normal;
        padding-bottom: 0;
    }

    .why_work_section {
        padding: 50px 0px;
    }

    .why_work_main {
        margin-top: 2rem;
    }

    .why_desc {
        transform: translate(5px, 24px);
        transition: all 0.5s;
    }

    .why_work_text_content img {
        margin-right: 1rem;
        width: 35px;
    }

    .why_work_text_content h3 {
        font-size: 22px;
    }

    .why_desc p {
        opacity: 0;
        transition: all 0.5s;
        margin-top: 5px;
        width: 100%;
        font-weight: 300 !important;
        line-height: 20px;
        color: #8b8b8b;
        margin-left: 0.2rem;
    }

    .why_work_icon img {
        width: 22px;
    }

    .why_work_main ul li:hover .why_desc p {
        opacity: 1;
        line-height: 20px;
        font-size: 15px;
        margin-left: 3.2rem;
        margin-top: 7px;
        width: 80%;
    }

    .start_proj_p {
        font-size: 16px;
    }

    .start_proj_main h2 {
        font-size: 24px;
        margin-top: 10px;
    }

    .start_proj_main:hover svg.arrow_hover {
        transform: translate3d(-105%, -15%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
    }

    .start_proj_main:hover .proj_start_title .hover_title {
        transform: translate3d(0px, -60px, 10px);
    }

    .arrow_hover {
        font-size: 55px;
    }

    .arrow_static {
        font-size: 55px;
    }

    .start_project_section {
        padding: 10px 0px 80px 0px;
    }

    .para_main_text h2 {
        font-size: 46px;
    }

    .para_item1 {
        position: absolute;
        left: 1.5rem;
        top: 10rem;
    }

    .para_item2 {
        position: absolute;
        left: inherit;
        right: 1.5rem;
        top: 13rem;
    }

    .para_item3 {
        position: absolute;
        left: 1.5rem;
        top: 20rem;
    }

    .para_item4 {
        position: absolute;
        left: inherit;
        top: 28rem;
        right: 1.5rem;
    }

    .para_item5 {
        position: absolute;
        left: 1.5rem;
        top: 23rem;
    }

    .para_item6 {
        position: absolute;
        left: inherit;
        right: 1.5rem;
        top: 26rem;
    }

    .para_item7 {
        position: absolute;
        left: 1.5rem;
        top: 35rem;
    }

    .para_item8 {
        position: absolute;
        left: inherit;
        right: 1.5rem;
        top: 36rem;
    }

    .para_item9 {
        position: absolute;
        left: 1.5rem;
        top: 40rem;
    }

    .para_item10 {
        position: absolute;
        left: inherit;
        right: 1.5rem;
        top: 45rem;
    }

    .para_item11 {
        position: absolute;
        left: 1.5rem;
        top: 48rem;
    }

    .para_item12 {
        position: absolute;
        left: inherit;
        right: 1.5rem;
        top: 50rem;
    }

    .para_item13 {
        position: absolute;
        left: 1.5rem;
        top: 53rem;
    }

    .para_main_text {
        margin: 0 auto;
    }

    .para_main_text.para_sticky-top {
        position: sticky;
        bottom: inherit;
        top: 10px;
        width: 80%;
    }

    /* .technologies_section {
        padding: 50px 0px 0px 0px;
        height: 1300px;
        display: block;
    } */
    .technologies_section {
        padding: 0px 0px 50px 0px;
        height: 100%;
        display: block;
    }

    .para_logo_main {
        position: relative;
        width: 100%;
        height: 100%;
        overflow: inherit;
    }



    .para_logo_main {
        display: grid;
        grid-template-columns: auto auto;
        padding-top: 80px;
    }

    .para_logo_main .item {
        position: inherit;
        text-align: center;
        margin-top: 1rem;
        left: inherit;
        right: inherit;
        top: inherit;
        bottom: inherit;
    }

    .para_logo_main .item img {
        max-width: 70%;
    }



}

@media (min-width: 320px) and (max-width: 335px) {
    .service_box p {
        line-height: 22px;
        font-size: 13px !important;
    }
}