@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;600;700&display=swap');




@font-face {
    font-family: 'TomatoGrotesk';
    src: url('../css/fonts/TomatoGrotesk-ExtraLight.otf');
}

/* @font-face {
     font-family: 'TomatoGrotesk';
     src: url('../css/fonts/TomatoGrotesk-Medium.otf');
     font-weight: medium;
  }
  
  @font-face {
     font-family: 'TomatoGrotesk';
     src: url('../css/fonts/TomatoGrotesk-Bold.otf');
    font-weight: bold;
  } */


body {
    font-family: 'TomatoGrotesk', sans-serif !important;
    background-color: #F9F9F9;
}

*{
  -webkit-tap-highlight-color: transparent;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 800 !important;
      
}

.active-scroll-spy {
  background-color: yellowgreen;
  border-radius: 15px;
}

.custome_container {
    max-width: 92% !important;
    margin: auto !important;
}

.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .timeline {
    height: 150px;
    width: 3px;
    background-color: #e5e5e5;
  }
  

  .timeline_main {
    position: relative;
}

.timeline_main .wrapper {
    position: absolute;
    left: 0px;
}
  
  .stub1 {
    line-height: 300px;
    font-size: 24px;
    background-color: #eae4e4;
  }
  
  .stub2 {
    height: 1000px;
  }
  
  .circle {
    width: 60px;
    height: 60px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: white;
    border-radius: 50%;
    /* border: 1px solid rgba(6, 6, 54, 1); */
  }
  
  .circleWrapper {
    position: relative;
  }
  
  .message {
    position: absolute;
    top: 20%;
    left: 50%;
    min-width: 150px;
    font-weight: bold;
  }